import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
const ListaPontosFixos = Loadable(lazy(() => import('./ListaPontosFixos')));
const FormularioPontosFixos = Loadable(lazy(() => import('./FormularioPontosFixos')));

const pontosFixosRoutes = [
    { path: '/pontos-fixos', element: <ListaPontosFixos /> },
    { path: '/pontos-fixos/new', element: <FormularioPontosFixos /> },
    { path: '/pontos-fixos/:id', element: <FormularioPontosFixos /> },
];

export default pontosFixosRoutes;